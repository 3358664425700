import { Link, useMatch } from "react-router-dom";
import PathConstants from "../../../routes/PathConstantsMain";
import { Container, Row, Navbar, Nav } from "react-bootstrap";
import ProjectConstants from "../../../projectConstants";

export default function Footer() {
  return (
	<footer className="footer mt-auto py-4">
		<div className="container">
			<div className="footer_menu">
				<Navbar>
					<Container fluid className="justify-content-center">
						<Row>
							<div className="col-xl-6 col-lg-4 col-md-12 text-center">
								<Navbar.Brand className="pb-4 pb-lg-2 pb-md-0" href="https://www.brj-berlin.de/" target="_blank" title="Zur Webseite des BRJ e. V.">© 2024-{(new Date().getFullYear())} {ProjectConstants.COMPANY_NAME}</Navbar.Brand>
							</div>
							<Nav className="col-xl-6 col-lg-8 col-md-12 text-center align-self-center justify-content-center d-md-flex d-block">
								<Nav.Link as={Link} to={PathConstants.UEBER_UNS} active={Boolean(useMatch(PathConstants.UEBER_UNS))}>Über uns</Nav.Link>
								<Nav.Link as={Link} to={PathConstants.KONTAKT} active={Boolean(useMatch(PathConstants.KONTAKT))}>Kontakt</Nav.Link>
								<Nav.Link as={Link} to={PathConstants.IMPRESSUM} active={Boolean(useMatch(PathConstants.IMPRESSUM))}>Impressum</Nav.Link>
								<Nav.Link as={Link} to={PathConstants.DATENSCHUTZ} active={Boolean(useMatch(PathConstants.DATENSCHUTZ))}>Datenschutz</Nav.Link>
							</Nav>
						</Row>
					</Container>
				</Navbar>
			</div>
		</div>
	</footer>
  );
}
